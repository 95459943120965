import React from 'react'
import Text from 'components/Text'
import EnterpriseFitnessLanding, {
  type EnterpriseFitnessLandingProps,
} from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

type Props = EnterpriseFitnessLandingProps

export default function EnterpriseSecurityHealthPlan(props: Props) {
  return (
    <EnterpriseFitnessLanding
      {...props}
      partner={enterprise.SECURITY_HEALTH_PLAN_KEY}
      heroProps={{
        title: 'Doctor-approved exercise to improve your health',
        subtext: (
          <>
            As a{' '}
            <Text color="yellow" weight="bold">
              Security Health Plan member with One Pass™
            </Text>
            , you have access to Bold to help better your balance, address bladder control, and
            increase strength and well-being — all at no cost.
          </>
        ),
      }}
      logos={LogosSvg}
      programs
      science={false}
      className="EnterpriseSecurityHealthPlan"
    />
  )
}
